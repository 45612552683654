import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/sales/form-penagihan',
        component: () => import('@/layout/BillingFormLayout.vue'),
        redirect: { name: 'WaitingBillingFormView' },
        children: [
            {
                path: 'menunggu-form-penagihan',
                name: 'WaitingBillingFormView',
                component: () => import('@/views/WaitingBillingFormView.vue'),
            },
            {
                path: 'selesai',
                name: 'CompletedBillingFormView',
                component: () => import('@/views/CompletedBillingFormView.vue'),
            },
            {
                path: 'detail',
                name: 'DetailBillingFormView',
                component: () => import('@/views/DetailBillingFormView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
